export const productTags = [
  {
    id: "promo",
    label: "En promo",
    color: "red",
    characteristics: ["en-promo"],
  },
  {
    id: "new",
    label: "Nouveau !",
    color: "cyan",
    characteristics: ["nouveau"],
  },
  {
    id: "fin-de-serie",
    label: "Fin de série",
    color: "amber",
    characteristics: ["fin-de-serie"],
  },
  {
    id: "bas-carbone",
    label: "Bas carbone",
    color: "green",
    characteristics: ["certification-carbone-ppe2", "certification-carbone-cre4"],
  },
] as const;
