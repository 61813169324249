import type { Cart } from "~types/api/cart.types";
import type { Contact } from "~types/api/contact.types";
import type { Product } from "~types/api/product.types";

import { productTags } from "~/config/tags";
import { getCharacteristicValue } from "~/services/characteristics";
import { formatCurrency, formatPercentage } from "~/utils/formatters";

export const isProductInPromotion = (product: Product) => {
  return (
    product.promotion &&
    (product.promoPrice || product.campaignType !== "R") &&
    (product.discountRate || product.discountAmount) &&
    product.campaignType &&
    getCharacteristicValue(product, "en-promo") === "1"
  );
};

export const getProductFinalPrice = (product: Product | null) => {
  if (!product) {
    return null;
  }

  if (!isProductInPromotion(product)) {
    return product.price || null;
  }

  return product.campaignType === "R" ? product.promoPrice : product.price || null;
};

export const getProductBarredPrice = (product: Product | null) => {
  if (!product || !isProductInPromotion(product)) {
    return null;
  }

  return product.campaignType === "R" ? product.price : product.promoPrice || null;
};

export const getProductWcPrice = (product: Product | null) => {
  if (!product || product?.categories?.[0]?.slug !== "modules-photovoltaiques") {
    return null;
  }

  const power = Number(getCharacteristicValue(product, "puissance"));

  if (!power || isNaN(power) || !(power > 0)) {
    return null;
  }

  const price = Number(getProductFinalPrice(product));

  if (!price || isNaN(price) || !(price > 0)) {
    return null;
  }

  return price / power;
};

export const isProductPriceVisible = ({
  product,
  contact,
}: {
  product: Product | null;
  contact: Contact | null | undefined;
}) => {
  if (!product?.price || !contact) {
    return false;
  }
  return (
    getCharacteristicValue(product, "article-sans-prix-de-vente") !== "1" &&
    contact.hasRestrictDisplayPrice !== true
  );
};

export const isProductQuantityDecimal = (product: Product) => {
  return getCharacteristicValue(product, "quantite-decimale") === "1";
};

export const isProductDeleted = (product: Product | null) => {
  return product?.deletedAt || product?.stocks[0]?.etatStock === -1;
};

export const isProductNotDisplayedOnExtranet = (product: Product | null) => {
  if (!product) {
    return false;
  }

  if (!getCharacteristicValue(product, "affichage-sur-extranet")) {
    return true;
  }

  return getCharacteristicValue(product, "affichage-sur-extranet") === "0";
};

export const isProductNoLongerOrdered = (product: Product | null) => {
  if (!product) {
    return false;
  }

  return getCharacteristicValue(product, "article-achete") === "0";
};

export const isCartRowPriceVisible = ({
  cartRow,
  contact,
}: {
  cartRow: Cart["cartRows"][0];
  contact: Contact | null | undefined;
}) => {
  return isProductPriceVisible({ product: cartRow.product, contact }) || cartRow.quotationRow;
};

export const isCartRowAvailable = ({
  cartRow,
  contact,
}: {
  cartRow: Cart["cartRows"][0];
  contact: Contact | null | undefined;
}) => {
  return isCartRowPriceVisible({ cartRow, contact }) && !isProductDeleted(cartRow.product);
};

export const cartHasUnavailableProducts = ({
  cart,
  contact,
}: {
  cart: Cart;
  contact: Contact | null | undefined;
}) => {
  return cart?.cartRows.some(
    (cartRow) => !isCartRowAvailable({ cartRow, contact }) && !cartRow.quotationRow
  );
};

export const cartHasUnavailableQuantity = (cart: Cart) => {
  return cart?.cartRows.some(
    (row) =>
      row.product.stocks[0]?.etatStock === 2 &&
      Number(row.quantity) > (Number(row.product.stocks[0]?.currentStock) || 0)
  );
};

export const getProductTags = (product: Product) => {
  return productTags
    .filter((tag) =>
      tag.characteristics.some(
        (characteristic) => getCharacteristicValue(product, characteristic) === "1"
      )
    )
    .map((tag) => {
      if (tag.id === "promo") {
        if (!isProductInPromotion(product)) {
          return null;
        }

        const isUserConnected = product?.price && product?.promoPrice;
        const isPercentagePromo = product?.discountRate && Number(product.discountRate) > 0;
        const isAmountPromo = product?.discountAmount && Number(product.discountAmount) > 0;
        const isRealPromo = product?.campaignType === "R";

        if (isRealPromo && !isUserConnected) {
          return null;
        }
        if (isPercentagePromo) {
          return {
            ...tag,
            label: `${tag.label} -${formatPercentage(product.discountRate)}`,
          };
        }
        if (isAmountPromo) {
          return {
            ...tag,
            label: `${tag.label} -${formatCurrency(product.discountAmount, 0)}`,
          };
        }
      }
      return tag;
    })
    .filter((tag) => tag !== null);
};

export const getProductPriceSuffix = (product: Product | null | undefined) => {
  if (!product) {
    return null;
  }

  if (product.categories[0]?.slug.startsWith("module")) {
    return " / module";
  }

  if (getCharacteristicValue(product, "cable-ac") === "1") {
    return " / Km";
  }

  return null;
};
